import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../theme/palette";
import Typography from "@mui/material/Typography";
import theme from "../theme";
import {Link} from "gatsby";

/* Article Top */
export const ArticleTop = styled(Box)({
  position: "relative",
  width: "100%",

  "& img": {
    width: "100%",
  },

  [theme.breakpoints.down("xl")]: {
    position: "unset",
    marginTop: "40px",

    "& img": {
      display: "none",
    },
  },
});

export const ArticleTopInfo = styled(Box)({
  position: "absolute",
  top: 0,
  left: "44px",
  width: "510px",
  padding: "26px 26px 58px",
  backgroundColor: palette.secondary,

  [theme.breakpoints.down("xl")]: {
    position: "unset",
    width: "100%",
    padding: 0,
  },
});

export const ArticleBackButton = styled(Link)({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
});

export const ArticleBackButtonImage = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "36px",
  height: "36px",
  marginRight: "12px",
  backgroundColor: palette.primary,

  "& svg": {
    transform: "rotate(180deg)",
    "& path": {
      fill: palette.secondary,
    },
  },
});

export const ArticleTopCategory = styled(Typography)({
  marginTop: "26px",
  color: "rgba(28, 28, 28, 0.5)",
  textTransform: "uppercase",
  letterSpacing: "0.1px",

  [theme.breakpoints.down("lg")]: {
    marginTop: "16px",
  },
});

export const ArticleTopTitle = styled(Typography)({
  margin: "18px 0",
  fontSize: "42px",
  lineHeight: "50px",

  [theme.breakpoints.down("lg")]: {
    fontSize: "42px",
    lineHeight: "50px",
  },

  [theme.breakpoints.down("md")]: {
    margin: "32px 0",
  },
});

export const ArticleTopDescription = styled(Typography)({
  color: palette.mediumGrey,

  [theme.breakpoints.down("lg")]: {
    color: palette.mediumBlack,
  },
});

/* Article Content */
export const ArticleContent = styled(Box)({
  maxWidth: "840px",
  width: "100%",
  margin: "40px auto 0",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "30px",
  },
});

/* Article Text Block */
export const ArticleTextBlock = styled(Box)({
  margin: "40px 0",

  [theme.breakpoints.down("md")]: {
    margin: "30px 0",
  },
});

export const ArticleTextBlockDescription = styled(Typography)({
  marginTop: "16px",
  color: palette.mediumGrey,
});

/* Article Quote Block */
export const ArticleQuoteBlock = styled(Box)({
  padding: "40px 0",

  [theme.breakpoints.down("md")]: {
    padding: "30px 0",
  },
});

export const ArticleQuoteBlockTitle = styled(Typography)({
  display: "flex",

  "& svg": {
    flexShrink: 0,
    margin: "12px 36px 0 0",
  },

  "@media(max-width: 375px)": {
    fontSize: "30px",
    lineHeight: "36px",

    "& svg": {
      width: "22px",
      height: "18px",
      marginRight: "20px",
    },
  },
});

export const ArticleQuoteBlockDescription = styled(Typography)({
  textAlign: "center",
  marginTop: "16px",
  paddingTop: "8px",
  color: palette.mediumGrey,
  fontStyle: "italic",
  borderTop: `1px solid ${palette.mediumGrey}`,
});

/* Article Column Block */
export const ArticleColumnBlock = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "40px 0",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },

  [theme.breakpoints.down("md")]: {
    padding: "30px 0",
  },
});

export const ArticleColumnBlockItem = styled(Box)({
  maxWidth: "385px",
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",

    "&:first-of-type": {
      marginBottom: "32px",
    },
  },
});

export const ArticleColumnBlockDescription = styled(Typography)({
  marginTop: "16px",
  color: palette.mediumGrey,
  lineHeight: "22px",
  letterSpacing: "0.44px"
});

/* Article Image Block */
export const ArticleImageBlock = styled(Box)({
  marginLeft: "-170px",
  padding: "40px 0",
  width: "calc(100% + 340px)",

  [theme.breakpoints.down("xl")]: {
    marginLeft: 0,
    width: "100%",
  },

  [theme.breakpoints.down("md")]: {
    padding: "30px 0",

    "& img": {
      width: "100%",
    },
  },
});

/* Article List Block */
export const ArticleListBlock = styled(Box)({
  padding: "40px 0",

  [theme.breakpoints.down("md")]: {
    padding: "30px 0",
  },
});

export const ArticleListBlockDescription = styled(Typography)({
  marginTop: "16px",
  paddingLeft: "20px",
  color: palette.mediumGrey,
  lineHeight: "22px",
  letterSpacing: "0.44px",

  "& li": {
    margin: 0,
    listStyleType: "disc",
  },
});

/* Article Advantages Block */
export const ArticleAdvantagesBlock = styled(Box)({
  padding: "40px 0",

  [theme.breakpoints.down("md")]: {
    padding: "30px 0",

    "& img": {
      display: "block",
      margin: "0 auto",
    },
  },
});

/* Article Message Block */
export const ArticleMessageBlock = styled(Box)({
  padding: "40px 0",

  [theme.breakpoints.down("md")]: {
    padding: "30px 0",
  },
});

export const ArticleMessageBlockInner = styled(Box)({
  padding: "40px 0",
  color: palette.mediumGrey,
  fontStyle: "italic",
  lineHeight: "20px",
  borderTop: `1px solid ${palette.mediumGrey}`,
  borderBottom: `1px solid ${palette.mediumGrey}`,
  textIndent: "40px",

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
});
