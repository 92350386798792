import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Seo from "../components/seo";
import Container from "../components/container";
import {
    ArticleAdvantagesBlock, ArticleBackButton, ArticleBackButtonImage,
    ArticleColumnBlock,
    ArticleColumnBlockDescription,
    ArticleColumnBlockItem,
    ArticleContent,
    ArticleImageBlock,
    ArticleListBlock,
    ArticleListBlockDescription,
    ArticleMessageBlock, ArticleMessageBlockInner,
    ArticleQuoteBlock,
    ArticleQuoteBlockDescription,
    ArticleQuoteBlockTitle,
    ArticleTextBlock,
    ArticleTextBlockDescription,
    ArticleTop,
    ArticleTopCategory,
    ArticleTopDescription,
    ArticleTopInfo,
    ArticleTopTitle
} from "../styles/article";
import { HOST_IMAGE_URL } from "../constants";
import Typography from "@mui/material/Typography";
import Quote from "../assets/icons/Quote";
import { useBreakpointValue } from "../hooks/use-breakpoint-value";
import BlogRecommendedPosts from "../components/articlesList/BlogRecommendedPosts";
import ArrowRight from "../assets/icons/ArrowRight";

const Article = ({
                     data: {
                         strapi: {
                             article: { data },
                         },
                     },
                 }) => {
    const isMobile = useBreakpointValue(["smallMobile", "mobile"]);

    return (
        <Layout>
            <Seo
                title={data?.attributes?.seo?.metaTitle}
                description={data?.attributes?.seo?.metaDescription}
                image={data?.attributes?.seo?.metaImage?.data?.attributes?.url}
            />
            <Container direction="column">
                <ArticleTop>
                    <img
                        src={`${
                            HOST_IMAGE_URL +
                            data?.attributes?.image?.data?.attributes?.url
                        }`}
                        alt={
                            data?.attributes?.image?.data?.attributes?.alternativeText
                        }
                        title={data?.attributes?.image?.data?.attributes?.caption}
                    />
                    <ArticleTopInfo>
                        <ArticleBackButton to='/blog'>
                            <ArticleBackButtonImage>
                                <ArrowRight />
                            </ArticleBackButtonImage>
                            <Typography variant="body2" fontWeight="500">Back</Typography>
                        </ArticleBackButton>
                        <ArticleTopCategory variant="subtitle1" component="div">
                            {data.attributes.category}
                        </ArticleTopCategory>
                        <ArticleTopTitle variant="h1">
                            {data.attributes.title}
                        </ArticleTopTitle>
                        <ArticleTopDescription
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: data.attributes.description,
                            }}
                        />
                    </ArticleTopInfo>
                </ArticleTop>
                <ArticleContent>
                    {data?.attributes?.elements?.map((element) => {
                        {/* Article Text Block */}
                        if (element?.__typename === "strapi_ComponentArticleTextBlock") {
                            return (
                                <ArticleTextBlock key={element.id}>
                                    <Typography variant="h4">
                                        {element?.title}
                                    </Typography>
                                    <ArticleTextBlockDescription
                                        variant="body2"
                                        dangerouslySetInnerHTML={{
                                            __html: element.description,
                                        }}
                                    />
                                </ArticleTextBlock>
                            );
                        }
                        {/* Article Quote Block */}
                        if (element?.__typename === "strapi_ComponentArticleQuoteBlock") {
                            return (
                                <ArticleQuoteBlock key={element.id}>
                                    <ArticleQuoteBlockTitle variant="h2" component="div">
                                        <Quote />{element?.title}
                                    </ArticleQuoteBlockTitle>
                                    <ArticleQuoteBlockDescription variant="subtitle2" component="div">
                                        {element.description}
                                    </ArticleQuoteBlockDescription>
                                </ArticleQuoteBlock>
                            );
                        }
                        {/* Article Column Block */}
                        if (element?.__typename === "strapi_ComponentArticleColumnBlock") {
                            return (
                                <ArticleColumnBlock key={element.id}>
                                    {element?.items?.map((item) => {
                                        return (
                                            <ArticleColumnBlockItem key={item.id}>
                                                <Typography variant="h4">
                                                    {item?.title}
                                                </Typography>
                                                <ArticleColumnBlockDescription
                                                    variant="body2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.description,
                                                    }}
                                                />
                                            </ArticleColumnBlockItem>
                                        )
                                    })}
                                </ArticleColumnBlock>
                            );
                        }
                        {/* Article Image Block */}
                        if (element?.__typename === "strapi_ComponentArticleImageBlock") {
                            return (
                                <ArticleImageBlock key={element.id}>
                                    {isMobile ? (
                                        <img
                                            src={`${
                                                HOST_IMAGE_URL +
                                                element?.mobileImage?.data?.attributes?.url
                                            }`}
                                            alt={element?.mobileImage?.data?.attributes?.alternativeText}
                                            title={element?.mobileImage?.data?.attributes?.caption}
                                        />
                                    ) : (
                                        <img
                                            src={`${
                                                HOST_IMAGE_URL +
                                                element?.image?.data?.attributes?.url
                                            }`}
                                            alt={element?.image?.data?.attributes?.alternativeText}
                                            title={element?.image?.data?.attributes?.caption}
                                        />
                                    )}
                                </ArticleImageBlock>
                            );
                        }
                        {/* Article List Block */}
                        if (element?.__typename === "strapi_ComponentArticleListBlock") {
                            return (
                                <ArticleListBlock key={element.id}>
                                    <Typography variant="h4">
                                        {element?.title}
                                    </Typography>
                                    <ArticleListBlockDescription
                                        variant="body2"
                                        dangerouslySetInnerHTML={{
                                            __html: element.list,
                                        }}
                                    />
                                </ArticleListBlock>
                            );
                        }
                        {/* Article Advantages Block */}
                        if (element?.__typename === "strapi_ComponentArticleAdvantagesBlock") {
                            return (
                                <ArticleAdvantagesBlock key={element.id}>
                                    {isMobile ? (
                                        <img
                                            src={`${
                                                HOST_IMAGE_URL +
                                                element?.mobileImage?.data?.attributes?.url
                                            }`}
                                            alt={element?.mobileImage?.data?.attributes?.alternativeText}
                                            title={element?.mobileImage?.data?.attributes?.caption}
                                        />
                                    ) : (
                                        <img
                                            src={`${
                                                HOST_IMAGE_URL +
                                                element?.image?.data?.attributes?.url
                                            }`}
                                            alt={element?.image?.data?.attributes?.alternativeText}
                                            title={element?.image?.data?.attributes?.caption}
                                        />
                                    )}
                                </ArticleAdvantagesBlock>
                            );
                        }
                        {/* Article Message Block */}
                        if (element?.__typename === "strapi_ComponentArticleMessageBlock") {
                            return (
                                <ArticleMessageBlock key={element.id}>
                                    <ArticleMessageBlockInner>
                                        <Typography variant="body2">
                                            {element?.content}
                                        </Typography>
                                    </ArticleMessageBlockInner>
                                </ArticleMessageBlock>
                            );
                        }
                        return null;
                    })}
                </ArticleContent>
                {/* Article Next To Read Block */}
                <BlogRecommendedPosts articles={data.attributes.nextToReadArticles} title="Next to Read" />
            </Container>
        </Layout>
    );
};

export default Article;

export const query = graphql`
  query caseQuery($id: ID!) {
    strapi {
      article(id: $id) {
        data {
          id
          attributes {
            seo {
              ...seo
            }
            slug
            title
            category
            description
            image {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            nextToReadArticles {
                data {
                    id
                    attributes {
                        slug
                        title
                        description
                        image {
                            data {
                                attributes {
                                    url
                                    alternativeText
                                    caption
                                }
                            }
                        }
                        createdAt
                    }
                }
            }
            elements {
                __typename
                
                ... on strapi_ComponentArticleTextBlock {
                    id
                    title
                    description
                }
                ... on strapi_ComponentArticleQuoteBlock {
                    id
                    title
                    description
                }
                ... on strapi_ComponentArticleColumnBlock {
                    id
                    items {
                        id
                        title
                        description
                    }
                }
                ... on strapi_ComponentArticleImageBlock {
                    id
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                    mobileImage {
                        data {
                            attributes {
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                }
                ... on strapi_ComponentArticleListBlock {
                    id
                    title
                    list
                }
                ... on strapi_ComponentArticleAdvantagesBlock {
                    id
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                    mobileImage {
                        data {
                            attributes {
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                }
                ... on strapi_ComponentArticleMessageBlock {
                    id
                    content
                }
            }
          }
        }
      }
    }
  }
`;
