import React from "react";

const Quote = ({ width = 42, height = 38 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M29.1047 38L25.6085 34.5021C27.3036 31.5342 28.7868 28.5662 30.0581 25.5983C31.3295 22.7364 32.0711 19.9275 32.2829 17.1715L24.3372 15.4226V0H41.5L41.5 11.1297C41.5 17.7015 40.2287 23.0014 37.686 27.0293C35.0375 31.1632 32.177 34.8201 29.1047 38ZM5.26744 38L1.77132 34.5021C3.46641 31.5342 4.94961 28.5662 6.22093 25.5983C7.49225 22.7364 8.23385 19.9275 8.44574 17.1715L0.5 15.4226L0.5 0L17.6628 0V11.1297C17.6628 17.7015 16.3915 23.0014 13.8488 27.0293C11.2003 31.1632 8.33979 34.8201 5.26744 38Z"
          fill="#9A9AAF"
      />
    </svg>
  );
};

export default Quote;
